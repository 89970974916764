import React, { useState, useEffect } from "react";
import {
  connect,
  useDispatch,
  useSelector,
} from "react-redux";
//i18n
import { withTranslation } from "react-i18next";
import { toggleCurrentModal } from "store/actions";
import { JClickHandler } from "./handlers";

const GoldJourney = (props) => {

  const { subPortal } = useSelector((state) => state.Layout);
  const isDemo = subPortal === "DEMO";

  const dispatch = useDispatch();

  const [stages, setStages] = useState({
    kycApproved: false,
    kycUpload: false,
    kycRejected: false,
    startTrading: false,
    individual: {
      submitProfile: false,
    },
    loaded: false,
  });
  useEffect(() => {
    if (props.stages && props.stages.kycUpload !== undefined) {
      setStages({
        ...stages,
        loaded: true,
        kycApproved: props.stages.kycApproved,
        kycUpload: props.stages.kycUpload,
        kycRejected: props.stages.kycRejected,
        startTrading: props.stages.startTrading,
        individual: {
          ...props.stages.individual,
          submitProfile: props.stages.individual && props.stages.individual.submitProfile,
        },
      });
    }
  }, [props.stages]);
  useEffect(() => {
    if (props.clientData && !props.clientData.isLead && !isDemo) {
      if (props.stages && stages.loaded) {
        if (!stages.individual.submitProfile) {
          dispatch(toggleCurrentModal("SubmitIndProfile"));
        }
        else if (!stages.kycUpload) {
          dispatch(toggleCurrentModal("UploadKycModal"));
        } else if (stages.kycUpload && !stages.kycApproved) {
          dispatch(toggleCurrentModal("KycPendingModal"));
        } 
      }
    }
    

  }, [stages, props.stages, isDemo]);

  const madeDeposit = props?.deposits?.length > 0;
  
  if (isDemo || (stages.individual.submitProfile && stages.kycApproved && madeDeposit)) return <></>;
  return (
    <div className='mb-5 forex'>
      {!isDemo && <React.Fragment>
        <h1 className='mb-2'>{props.t("How it works")}</h1>
        <p className='text-muted'>{props.t("Get started with 3 easy steps")}</p>
        <div className='steps mb-5'>
          <div className={stages.individual.submitProfile ? "steps-card steps-complete text-center" : "steps-card text-center"}
            onClick={stages.individual.submitProfile ? null : JClickHandler("goldJourney", stages, dispatch, toggleCurrentModal, "GOLD")}
          >
            <div className='number'>1</div>
            <div className='steps-card-title-container mb-3'>
              <span className='steps-card-title'>
                {props.t("Sign up")}
                <span className='custom-border'></span>
              </span>
            </div>
          </div>
          <div className={stages.kycApproved || stages.kycRejected ? "steps-card steps-complete text-center" : "steps-card text-center"}
            onClick={(stages.kycApproved || stages.kycRejected) ? null : JClickHandler("goldJourney", stages, dispatch, toggleCurrentModal, "GOLD")}
          >
            <div className='number'>2</div>
            <div className='steps-card-title-container mb-3'>
              <span className='steps-card-title'>
                {props.t("Verify")}
                <span className='custom-border'></span>
              </span>
            </div>
          </div>
          <div className={madeDeposit ? "steps-card steps-complete text-center" : "steps-card text-center"}
            onClick={() => {
              if (!madeDeposit)
                JClickHandler("goldJourney", stages, dispatch, toggleCurrentModal, "GOLD")();
            }}
          >
            <div className='number'>3</div>
            <div className='steps-card-title-container mb-3'>
              <span className='steps-card-title'>
                {props.t("Fund Now")}
                <span className='custom-border'></span>
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  stages: (state.Profile.clientData && state.Profile.clientData.stages) || {},
  clientData: state.Profile.clientData || {},
  deposits: state.goldReducer.deposits.deposits || [],
});
export default connect(mapStateToProps, null)(withTranslation()(GoldJourney));