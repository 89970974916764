import { lazy } from "react";
import { Redirect } from "react-router-dom";
const Dashboard = lazy(() => import("pages/Forex/Dashboard/index"));
const Accounts = lazy(() => import("pages/Forex/Accounts"));
const Login = lazy(() => import("pages/Authentication/Login"));
const ChangePassword = lazy(() => import("pages/Forex/ChangePassword"));
const InternalTransfer = lazy(() => import("pages/Forex/InternalTransfer"));
const ibInternalTransfer = lazy(() => import("pages/Forex/Partnership/InternalTransfer"));
const Withdraw = lazy(() => import("pages/Forex/Withdraw"));
const Reports = lazy(() => import("pages/Forex/Reports"));
const Platforms = lazy(() => import("pages/Forex/Platforms"));
const Deposit = lazy(() => import("pages/Forex/Deposit"));

// my profile menu
const Profile = lazy(() => import("pages/Authentication/Profile"));
const Activites = lazy(() => import("pages/Forex/Activites"));
const Documents = lazy(() => import("pages/Documents"));
const Applications = lazy(() => import("pages/Forex/Applications/"));
const WebTrader = lazy(() => import("pages/Forex/WebTrader"));
const BankAccounts = lazy(() => import("pages/Forex/BankAccounts/BankAccounts"));
const TwoFA = lazy(() => import("../pages/Authentication/2FA"));
const TradingAccount = lazy(() => import("pages/Forex/TradingAccount/index"));
const Statment = lazy(() => import("pages/Forex/Statement"));
const Partnership = lazy(() => import("pages/Forex/Partnership/Partnership"));
const Referrals = lazy(() => import("pages/Forex/Partnership/Referrals"));
const RequestPartnership = lazy(() => import("pages/Forex/Partnership/RequestPartnership"));
const IbClients = lazy(() => import("pages/Forex/Partnership/Clients"));
const Wallet = lazy(() => import("pages/Wallet"));

import nonAuthRoutes from "./nonAuthRoutes";
import Notifications from "pages/Authentication/Notifications";

export default [
  {
    path: "/dashboard",
    component: Dashboard,
    isAuth: true,
  },
  {
    path: "/wallet",
    component: Wallet,
    isAuth: true
  },
  {
    path: "/accounts/password",
    component: ChangePassword,
    isAuth: true,
  },
  {
    path: "/accounts/:type",
    component: Accounts,
    isAuth: true,
  },
  {
    path: "/deposit",
    component: Deposit,
    isAuth: true,
  },
  {
    path: "/profile",
    component: Profile,
    isAuth: true,
  },
  {
    path: "/activites",
    component: Activites,
    isAuth: true,
  },
  {
    path: "/documents",
    component: Documents,
    isAuth: true,
  },
  {
    path: "/application",
    component: Applications,
    exact: true,
    isAuth: true,
  },
  {
    path: "/withdraw",
    component: Withdraw,
    isAuth: true,
  },
  {
    path: "/transfer",
    component: InternalTransfer,
    isAuth: true,
  },
  {
    path: "/reports",
    component: Reports,
    isAuth: true,
  },
  {
    path: "/platforms",
    component: Platforms,
    isAuth: true,
  },
  {
    path: "/ib/clients/:type",
    component: IbClients,
    isAuth: true
  },
  {
    path: "/ib/transfer",
    component: ibInternalTransfer,
    exact: true,
    isAuth: true
  },
  {
    path: "/wallet",
    component: Wallet,
    isAuth: true 
  },
  {
    path:"/web-trader",
    component: WebTrader,
    isAuth: true,
  },
  {
    path: "/trading-account",
    component: TradingAccount,
    isAuth: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
    isAuth: false,
  },
  {
    path: "/bank-accounts",
    component: BankAccounts,
    exact: true,
    isAuth: true
  },
  {
    path: "/two-fa",
    component: TwoFA,
    isAuth: true
  },
  {
    path: "/partnership",
    component: Partnership,
    exact: true,
    isAuth: true
  },
  {
    path: "/referrals",
    component: Referrals,
    exact: true,
    isAuth: true
  },
  {
    path: "/statement",
    component: Statment,
    exact: true,
    isAuth: true
  },
  {
    path: "/request-partnership",
    component: RequestPartnership,
    exact: true,
    isAuth: true
  },
  {
    path: "/notifications",
    component: Notifications,
    isAuth: true
  },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    isAuth: true,
    component: () => <Redirect to="/dashboard" />
  },
  ...nonAuthRoutes
];
