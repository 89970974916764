import React from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
import CustomTable from "../../components/Common/CustomTable";
import * as content from "content";
import axios from "axios";

function DocumentList(props) {
  const imagesUrl = content.imagesUrl;

  const docs = props.documents?.map((obj) => {
    let arr = [obj.file1];
    if (obj.file2) {
      arr.push({ originalname: " " });
      arr.push(obj.file2);
    }
    obj.files = arr;

    return obj;
  });

  // const [deleteModal, setDeleteModal] = React.useState({
  //   show: false,
  //   documentId: null,
  //   clientId: null,
  // });

  function titleCase(str) {
    let tmpArr = str.toLowerCase().split("_");

    return tmpArr
      .map((obj) => {
        return obj[0].toUpperCase() + obj.slice(1);
      })
      .join(" ");
  }

  function getFileLink(obj, index, id) {
    if (!obj || !obj.url) return <></>;
    const getSecureLink = () => {
      axios.get(`${content.apiUrl}/api/v1/crm/documents/${id}/${index}`, {
        headers: {
          "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser"))?.token}`
        }
      }).then(res => {
        window.open(res.data.result, "_blank");
      }
      ).catch(err => {
        console.log(err);
      });
    };
    return <div><a onClick={() => getSecureLink(obj.url)} style={{
      color: "#405189",
      cursor: "pointer"
    }}  >{obj?.filename?.split("/")?.pop() || obj?.fileName?.split("/")?.pop() || index}</a></div>;
  }

  const columns = [
    {
      dataField: "createdAt",
      text: props.t("Created Date"),
      formatter: (val) => new Date(val.createdAt).toLocaleDateString(),
    },
    {
      dataField: "type",
      text: props.t("Type"),
      formatter: (val) => titleCase(val.type),
    },
    {
      dataField: "files",
      text: props.t("Files"),
      formatter: (val) => val.files.map((obj, index) => getFileLink(obj, index + 1, val?._id)),
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => titleCase(val.status),
    },
    {
      dataField: "status",
      text: "",
      formatter: (val) => {
        return val.rejectionReason ? val.rejectionReason : "";
      },
    },
    // {
    //   dataField: "",
    //   isDummyField: true,
    //   editable: false,
    //   text: props.t("Action"),
    //   formatter: (item, index) => (
    //     <div className="d-flex gap-3">
    //       {
    //         ["PENDING", "EXPIRED", "IN_PROGRESS"].indexOf(item.status) > -1 &&
    //         <React.Fragment>
    //           <Link className="text-danger" to="#">
    //             <i
    //               className="mdi mdi-delete font-size-18"
    //               id="deletetooltip3"
    //               onClick={() => {
    //                 setDeleteModal({
    //                   show: true,
    //                   customerId: item.customerId,
    //                   documentId: item._id,
    //                   index,
    //                   onClose: () => {
    //                     setDeleteModal({
    //                       ...deleteModal,
    //                       show: false,
    //                     });
    //                   }
    //                 });
    //               }}
    //             ></i>
    //           </Link>
    //         </React.Fragment>
    //       }
    //       {["PENDING", "EXPIRED", "IN_PROGRESS"].indexOf(item.status) === -1 && <React.Fragment>----</React.Fragment>}
    //     </div>
    //   ),
    // },
  ];
  if (props.loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {props.documents?.length > 0 ? (
        <CustomTable columns={columns} rows={docs} />
      ) : (
        <div className="d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
          {props.t("No document uploaded")}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.documents.loading,
  clearChangeStatus: state.documents.clearChangeStatus,
  clearDelete: state.documents.clearDelete,
  documents: state.documents.documents,
  error: state.documents.error,
});
export default connect(mapStateToProps, null)(withTranslation()(DocumentList));
