import logo from "assets/images/brands/brand-main-sm.png";

export const clientName = "Exinitic";
export const developedBy = "Exinitic";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "Exinitic";
export const companyWebsite = "https://exinitic.com/";
export const privacyPolicyLink = "#";
export const clientAgreementLink = "#";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
